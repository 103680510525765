import { BankOutlined, BarChartOutlined, BellOutlined, CalendarOutlined, CheckOutlined, ClockCircleOutlined, CreditCardOutlined, FileOutlined, FireOutlined, LoginOutlined, MoneyCollectOutlined, SettingOutlined, SmileOutlined, SolutionOutlined, TeamOutlined, } from '@ant-design/icons';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}
export const itemsMain: MenuItem[] = [
    getItem('일정', '/schedule', <CalendarOutlined />),
    getItem('라운지-Week', '/week', <SmileOutlined />),
    getItem('예약', '/reservation', <ClockCircleOutlined />),
    getItem('출석내역', '/check-in/history', <LoginOutlined />),
    getItem('웰니스수업', '/wellness-class', <FireOutlined />),
    getItem('회원', '/member', <TeamOutlined />),
    getItem('코치님', '/teacher', <SolutionOutlined />),
    getItem('정기권', '/wellness-ticket', <CreditCardOutlined />),
    getItem('쿠폰', '/coupon', <CreditCardOutlined />),
    getItem('알림', '#sub10', <BellOutlined />, [
        getItem('푸시 알림 발송', '/notification/create'),
        getItem('발송 완료함', '/notification'),
        getItem('예약 발송함', '/notification/reservation'),
        getItem('임시 저장함', '/notification/temp'),
    ]),

    getItem('회사 관리', '/company', <BankOutlined />),
    getItem('설정', '#sub12', <SettingOutlined />, [
        getItem('기본 정보', '/setting/basic'),
        getItem('상세 정보', '/setting/detail'),
        getItem('요가원 사진 등록', '/setting/image-management'),
        getItem('시간표', '/setting/schedule'),
        getItem('공지사항', '/setting/notice-popup'),
        getItem('이벤트', '/setting/event'),
        getItem('배너', '/setting/banner'),
        getItem('앱 다운로드', '/setting/schedule-info'),
    ]),
    getItem('통계', '#sub13', <BarChartOutlined />, [
        {
            key: "#sub131", label: "고객분석", children: [
                { key: '/report/member/daily', label: '요일별 분석' },
                { key: '/report/member/time', label: '시간별 분석' },
            ]
        },
        {
            key: "#sub132", label: "매출", children: [
                { key: '/report/sales/daily', label: '일별 분석' },
                { key: '/report/sales/weekly', label: '주별 분석' },
                { key: '/report/sales/monthly', label: '월별 분석' },
            ]
        },
        {
            key: "#sub133", label: "정기권", children: [
                { key: '/report/ticket/sold/rank', label: '판매 순위' },
                { key: '/report/ticket/retention/rank', label: '보유 현황' },
                { key: '/report/ticket/convention', label: '구매 전환' },
            ]
        },
        {
            key: "#sub134", label: "예약·출석", children: [
                { key: '/report/reservation/center', label: '센터별' },
                { key: '/report/reservation/daily', label: '요일별' },
                { key: '/report/reservation/weekly', label: '주별' },
                { key: '/report/reservation/monthly', label: '월별' },
                { key: '/report/reservation/ticket', label: '정기권별' },
            ]
        },
        {
            key: "#sub135", label: "코치", children: [
                { key: '/report/teacher/reservation', label: '예약·출석별' },
                { key: '/report/teacher/time', label: '시간별' },
                { key: '/report/teacher/daily', label: '요일별' },
                { key: '/report/teacher/weekly', label: '주별' },
            ]
        }
    ]),
    getItem('매출', '/revenue', <CreditCardOutlined />),
    getItem('데이터 이관', '/data-escalation', <FileOutlined />),
    getItem('출석', '/check-in', <CheckOutlined />),

    getItem('로그인', '/login', <LoginOutlined />)
];