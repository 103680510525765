import { Button, Form, Input, Select, Spin, UploadFile } from "antd";
import { useEffect, useState } from "react";
import UploadCropCustom from "../../../../components/ImageUpload/crop";
import { useNavigate, useParams } from "react-router-dom";
import { getScheduleInfoByScheduleInfoId, updateScheduleInfoByScheduleInfoUpdateAdminRequest } from "../../../../service/scheduleInfo";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllYogaCenterIdAndNameByCompanyId } from "../../../../service/yogaCenter";

interface IFinishData {
    id: string;
    name: string;
    yogaCenterId: number;
    imgFileList: UploadFile[];
}

const SettingScheduleInfoDetail = () => {
    const { id } = useParams();
    const [imgFileList, setImgFileList] = useState<UploadFile[]>([])
    const queryClient = useQueryClient();
    const { data, isSuccess, isError, error, isPending } = useQuery({
        queryKey: ['getScheduleInfoByScheduleInfoId', id],
        queryFn: () => id ? getScheduleInfoByScheduleInfoId(id) : Promise.reject(),
        enabled: Boolean(id),
        select: q => q.data
    })
    const { data: data2, isPending: isPending2 } = useQuery({
        queryKey: ['getAllYogaCenterIdAndNameByCompanyId'],
        queryFn: () => getAllYogaCenterIdAndNameByCompanyId(1),
        select: q => q.data
    })
    const updateQuery = useMutation({
        mutationFn: (request: IScheduleInfoUpdateAdminRequest) => updateScheduleInfoByScheduleInfoUpdateAdminRequest(request),
        onSuccess: (res) => { console.log('Success:', res); queryClient.invalidateQueries({ queryKey: ['getAllScheduleInfo'] }); navigate('/setting/schedule-info') },
        onError: (error) => console.error('Error:', error),
    });
    const navigate = useNavigate();


    const onFinish = (value: IFinishData) => {
        const request: IScheduleInfoUpdateAdminRequest = {
            id: value.id,
            name: value.name,
            imgFile: value.imgFileList.filter(q => q.originFileObj).map(q => q.originFileObj)[0] ?? null,
        }
        updateQuery.mutate(request);
    }
    useEffect(() => {
        if (isError) {
            console.error('Error: ', error);
        }
        if (isSuccess) {

            setImgFileList([{
                uid: data.id.toString(),
                name: data.imgUrl.split('/').pop() ?? data.imgUrl + '_' + data.id.toString(),
                status: 'done',
                url: data.imgUrl,
                thumbUrl: data.imgUrl
            }])
        }

    }, [isSuccess, isError, error, data]);

    return <>
        {isPending && <Spin />}
        {isSuccess && <Form
            onFinish={onFinish}
            labelAlign='left'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            colon={false}
        >
            <Form.Item name='id' hidden initialValue={data.id} ><Input /></Form.Item>
            {/* <Form.Item name='name' label='탭 이름' initialValue={res.data.name}>
                <Input style={{ width: '300px' }} />
            </Form.Item> */}
            <Form.Item name='yogaCenterId' label='요가원' initialValue={data.yogaCenterId}>
                <Select
                    loading={isPending2}
                    style={{ width: '300px' }}
                    options={data2?.map(q => ({ value: q.id, label: q.name, key: q.id }))}
                    disabled
                />
            </Form.Item>
            <Form.Item name='imgFileList' label='이미지' valuePropName="fileList" getValueProps={() => ({})} >
                <UploadCropCustom setFileList={setImgFileList} fileList={imgFileList} maxImg={1} uploadIconText={"+"} />
            </Form.Item>

            <div style={{ textAlign: 'right' }}>
                <Button style={{ marginRight: '10px' }} danger onClick={() => navigate(-1)} disabled={updateQuery.isPending} >취소</Button>
                <Button type='primary' htmlType='submit' loading={updateQuery.isPending} >수정</Button>
            </div>
        </Form>}
    </>
}

export default SettingScheduleInfoDetail;