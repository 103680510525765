import { Button, Form, message, Select, UploadFile } from "antd";
import { useState } from "react";
import UploadCropCustom from "../../../../components/ImageUpload/crop";
import { createScheduleInfoByScheduleInfoCreateAdminRequest, getAllScheduleInfo } from "../../../../service/scheduleInfo";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getAllYogaCenterIdAndNameByCompanyId } from "../../../../service/yogaCenter";

interface IFinishData {
    name: string;
    yogaCenterId: number;
    imgFileList: UploadFile[];
}

const SettingScheduleInfoCreate = () => {
    const [imgFileList, setImgFileList] = useState<UploadFile[]>([])
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data, isPending } = useQuery({
        queryKey: ['getAllYogaCenterIdAndNameByCompanyId'],
        queryFn: () => getAllYogaCenterIdAndNameByCompanyId(1),
        select: q => q.data
    })
    const { data: dataAll } = useQuery({
        queryKey: ['getAllScheduleInfo'],
        queryFn: () => getAllScheduleInfo(),
        staleTime: 1000 * 60 * 5,
        select: q => q.data
    })
    const createQuery = useMutation({
        mutationFn: (request: IScheduleInfoCreateAdminRequest) => createScheduleInfoByScheduleInfoCreateAdminRequest(request),
        onSuccess: (res) => { console.log('Success:', res); queryClient.invalidateQueries({ queryKey: ['getAllScheduleInfo'] }); navigate('/setting/schedule-info') },
        onError: (error) => console.error('Error:', error),
    });

    const onFinish = (value: IFinishData) => {
        if (dataAll?.map(q => q.yogaCenterId).includes(value.yogaCenterId)) {
            return message.error('이미 생성한 요가원입니다.');
        }
        const request: IScheduleInfoCreateAdminRequest = {
            name: value.name,
            yogaCenterId: value.yogaCenterId,
            imgFile: value.imgFileList.map(file => file.originFileObj)[0]
        }
        createQuery.mutate(request)
    }

    return <>
        <Form
            onFinish={onFinish}
            labelAlign='left'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            colon={false}
        >
            <Form.Item name='yogaCenterId' label='요가원' rules={[{ required: true, message: '요가원을 선택해주세요' }]}>
                <Select
                    loading={isPending}
                    style={{ width: '300px' }}
                    options={data?.map(q => ({ value: q.id, label: q.name, key: q.id }))}
                />
            </Form.Item>
            {/* <Form.Item name='name' label='탭 이름'>
                <Input style={{ width: '300px' }} />
            </Form.Item> */}
            <Form.Item name='imgFileList' label='이미지 리스트' valuePropName="fileList" getValueProps={() => ({})} >
                <UploadCropCustom setFileList={setImgFileList} fileList={imgFileList} maxImg={1} uploadIconText={"+"} />
            </Form.Item>

            <div style={{ textAlign: 'right' }}>
                <Button type='primary' htmlType='submit' loading={createQuery.isPending} >저장</Button>
            </div>
        </Form>
    </>
}

export default SettingScheduleInfoCreate;